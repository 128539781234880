<template>
  <RikazInfiniteVScroll
    :auto="auto"
    :infinite-id="infiniteId"
    :has-initial-cached-materials="hasInitialCachedMaterials"
    :parent-selector="parentSelector"
  >
    <template #loading>
      <SelaLoadingIndicator :size="10" color="border-t-nav_color p-5" />
    </template>

    <template #completed>
      <div class="text-center">{{ loadedText || t('loaded') }}</div>
    </template>

    <template #noResults>
      <div class="max-w-xs mx-auto text-center">
        <SelaFetchError :message="noResultsText || t('no_results')">
          <v-icon icon="mdi mdi-emoticon-sad-outline" :size="48" />
        </SelaFetchError>
      </div>
    </template>
  </RikazInfiniteVScroll>
</template>

<script setup lang="ts">
defineProps({
  loadedText: { type: String, default: null },
  noResultsText: { type: String, default: null },
  hasInitialCachedMaterials: { type: Boolean, default: false },
  infiniteId: { type: Number, default: 0 },
  parentSelector: { type: String, default: null },
  lengthElement: { type: Number, default: 0 },
  auto: { type: Boolean, default: true }
})

const { t } = useI18n()
</script>
